import * as Sentry from '@sentry/vue';
import Vue from 'vue';
const ENV = process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV;

if (!['development', 'test'].includes(ENV) && !window.Cypress) {
    Sentry.init({
        Vue,
        dsn: 'https://66d89778b9054541aeff38e1ecd38b67@o527262.ingest.sentry.io/5658866',
        environment: ENV || 'production',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.vueIntegration({
                tracingOptions: {
                    trackComponents: true,
                },
            }),
        ],
        tracesSampleRate: 1.0,
    });
}
